import { ReactNode } from 'react';

import { Locale } from '@travel/i18n/src/config/types';
import { getPopularDestinationsWithLocaleInfo } from '@travel/i18n/src/utils/l10n';
import { SuggestionType } from '@travel/traveler-core/types/suggestions';
import isNotEmptyArray from '@travel/ui/utils/arrays';

import { SuggestionsItem, SuggestionsSection } from 'Suggestions-Types';

export function getPopularDestinations(
  market: string,
  lang: string,
): Array<SuggestionsItem> | null {
  const locations = getPopularDestinationsWithLocaleInfo(market);

  let locationsLabels: Array<SuggestionsItem> = [];
  if (isNotEmptyArray(locations)) {
    locationsLabels = locations.map(location => {
      const locale = location.locale.find(
        (locale: Locale) => locale.code.toLowerCase() === lang.toLowerCase(),
      );
      return { name: locale?.label, category: 'POPULAR_DESTINATION' } as SuggestionsItem;
    });
  }
  return locationsLabels;
}

export function convertToSuggestionSection(
  suggestions: Array<SuggestionsItem>,
  title: ReactNode = null,
  type: SuggestionType = SuggestionType.API_SUGGESTIONS,
): SuggestionsSection {
  return {
    title: title,
    type: type,
    suggestions: suggestions,
  };
}
