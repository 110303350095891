import React from 'react';

import SalesPromotionInputCore from '@travel/traveler-core/components/SalesPromotionInput';

export type SalesPromotionFormQuery = {
  /** String of exact sales promotion id */
  salesPromotionId?: string;
  /** String of promotion code */
  promotionCode?: string;
};

export type InternalSalesPromotionFormQuery = {
  /** String if navigated from Coupon Search form */
  isCouponSpecified?: string;
};

function SalesPromotionInput(props: SalesPromotionFormQuery & InternalSalesPromotionFormQuery) {
  return <SalesPromotionInputCore {...props} />;
}

export default SalesPromotionInput;
