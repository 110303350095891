import React, {
  useState,
  ReactNode,
  ReactElement,
  FormEvent,
  HTMLProps,
  useRef,
  useCallback,
} from 'react';
import Autosuggest, {
  RenderInputComponentProps,
  SuggestionsFetchRequestedParams,
} from 'react-autosuggest';
import { as } from '@travel/utils';

import cx from '../../utils/classnames';
import { debounce } from 'throttle-debounce';

import styles from './autoCompleteMulti.module.scss';

const defaultGetSuggestionValue = (suggestion: ReactNode) => suggestion;

// About "any": Didn't find any way to pass TSuggestion to here, and it only affects a few lines below so let's keep it
const defaultRenderSuggestion = (
  renderSuggestionContent: (suggestion: any) => ReactNode = (suggestion: any) => suggestion,
) => (suggestion: any, { isHighlighted }: { isHighlighted: boolean }) => {
  return (
    <button
      type="button"
      className={[styles.suggestion, isHighlighted ? styles.highlightedSuggestion : null].join(' ')}
    >
      {renderSuggestionContent(suggestion)}
    </button>
  );
};

type WithRef = { ref: (x: HTMLInputElement | null) => void };

type Props<TSuggestion, TSuggestionSection> = {
  /** suggestion list is always rendered regardless */
  isAlwaysRenderSuggestions?: boolean;
  /** container className */
  className?: string;
  /** The number of milliseconds to delay */
  delayTime: number;
  /** Value of Text component. */
  value: string;
  /** Set it to true if you'd like Autosuggest to automatically highlight the first suggestion. */
  isHighlightFirstSuggestion?: boolean;
  /** Set it to true if you'd like Autosuggest to automatically highlight the first suggestion. */
  shouldFocusInputOnSuggestionClick?: boolean;
  /** This function is for parent component to access AutoComplete when input value changes */
  onInputChange: (value: string, method?: Autosuggest.ChangeEvent['method'] | 'clear') => void;
  /** This function is triggered on blur  */
  onBlur?: (event: React.FocusEvent<any>, params?: Autosuggest.BlurEvent<TSuggestion>) => void;
  /** This is triggered when input value becomes empty */
  onSuggestionsClearRequested: () => void;
  /** This function is triggered when input is changed by typing and its value is not empty. */
  onSuggestionsFetchRequested: (value: string) => void;
  /** This function is triggered when user clicks a suggestion. */
  onSuggestionSelected: (
    event: FormEvent<any>,
    { suggestion }: { suggestion: TSuggestion },
  ) => void;
  /** When multi section set to true, this is mandatory callback to render the section section title */
  renderSectionTitle?: (section: TSuggestionSection) => ReactNode;
  /** When multi section set to true, this is mandatory callback to render the sections */
  getSectionSuggestions: (section: TSuggestionSection) => Array<TSuggestion>;
  /** Set the value on text input when user selects a suggestion.
   *  This function is useful when suggestions are object like { key: foo, value: bar }.
   *  Example: suggestion => suggestion.value
   *  Default: suggestion => suggestion
   */
  getSuggestionValue: Autosuggest.GetSuggestionValue<TSuggestion>;
  /** The prop to customize how to render suggestion. This prop is exported from react-suggestion component.
   *  Please refer to https://github.com/moroshko/react-autosuggest#rendersuggestion-required
   */
  renderSuggestion?: (
    suggestion: TSuggestion,
    { query, isHighlighted }: Autosuggest.RenderSuggestionParams,
  ) => ReactNode;
  /** The prop to customize how to render suggestion. Comparing with renderSuggestion, it only change the text
   *  content of each suggestion element.
   *  This function is useful when suggestions are object like { text: foo, value: bar }.
   *  Example: suggestion => suggestion.text
   *  Default: suggestion => suggestion
   */
  renderSuggestionContent?: (suggestion: TSuggestion) => string;
  /** The prop to customize how to render suggestion container. This prop is exported from react-suggestion component.
   *  Please refer to https://github.com/moroshko/react-autosuggest#rendersuggestionscontainer-optional
   */
  renderSuggestionsContainer?: ({
    containerProps,
    children,
    query,
  }: Autosuggest.RenderSuggestionsContainerParams) => ReactNode;
  /** The prop to customize suggestions rendering behavior.
   *  With this, you can control when not to render suggestions.
   *  Example: When input < 3 characters
   *  Please refer to https://github.com/moroshko/react-autosuggest#shouldrendersuggestions-optional
   */
  shouldRenderSuggestions?: () => boolean;

  /** Raw data of suggestions */
  suggestions: Array<TSuggestionSection>;

  /** Function to pass input reference to parent */
  inputRef?: (instance: HTMLInputElement | null) => void;

  /** Customized component inside text input */
  children?: ReactElement;
} & Omit<HTMLProps<HTMLDivElement>, 'onBlur'>;

function AutoCompleteMulti<TSuggestion = any, TSuggestionSection = any>(
  props: Props<TSuggestion, TSuggestionSection>,
) {
  const {
    isAlwaysRenderSuggestions,
    className,
    delayTime,
    suggestions,
    isHighlightFirstSuggestion,
    shouldFocusInputOnSuggestionClick,
    onInputChange,
    onClick,
    onBlur,
    onKeyDown,
    onSuggestionSelected,
    onSuggestionsFetchRequested,
    onSuggestionsClearRequested,
    getSuggestionValue,
    renderSuggestion,
    renderSuggestionContent,
    renderSuggestionsContainer,
    renderSectionTitle,
    getSectionSuggestions,
    inputRef,
    shouldRenderSuggestions,
    ...rest
  } = props;

  if (renderSuggestion && renderSuggestionContent) {
    console.warn(
      'AutoComplete: renderSuggestionContent will be ignored When renderSuggestion is used. You can try to remove renderSuggestionContent.',
    );
  }

  const [value, setValue] = useState(props.value || '');

  const { current: isControlled } = useRef(props.value !== null);

  const handleOnSuggestionsFetchRequested = useCallback(
    debounce(delayTime, ({ value, reason }: SuggestionsFetchRequestedParams) => {
      if (reason !== 'input-focused') {
        onSuggestionsFetchRequested(value);
      }
    }),
    [],
  );

  const renderInputComponent = (inputProps: RenderInputComponentProps): ReactNode => {
    const { ...rest } = inputProps; // This ref prop is from react-autoSuggest library
    // ref from react-autoSuggest is not defined in the library's type definition.
    // Therefore the corresponding type should be provided manually.
    const { ref } = as<WithRef>(inputProps);
    return (
      props.children &&
      React.cloneElement(props.children, {
        ...rest,
        inputRef: (el: HTMLInputElement | null) => {
          ref(el);
          if (inputRef) inputRef(el);
        },
      })
    );
  };

  const inputProps = {
    value: isControlled ? props.value : value,
    onChange: (event: FormEvent<HTMLElement>, { newValue, method }: Autosuggest.ChangeEvent) => {
      isControlled ? onInputChange(newValue, method) : setValue(newValue);
    },
    onClick: onClick,
    onBlur: onBlur,
    onKeyDown: onKeyDown,
    onFocus: props.onFocus,
    onClear: () => {
      isControlled ? onInputChange('', 'clear') : setValue('');
    },
  };

  return (
    <div className={cx(styles.autoComplete, className)} {...rest}>
      <Autosuggest<TSuggestion, TSuggestionSection>
        multiSection={true}
        alwaysRenderSuggestions={isAlwaysRenderSuggestions}
        theme={{
          suggestionsContainer: styles.suggestionsContainer,
        }}
        focusInputOnSuggestionClick={shouldFocusInputOnSuggestionClick}
        highlightFirstSuggestion={isHighlightFirstSuggestion}
        suggestions={suggestions}
        getSectionSuggestions={getSectionSuggestions}
        renderSectionTitle={renderSectionTitle}
        onSuggestionsFetchRequested={handleOnSuggestionsFetchRequested}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        onSuggestionSelected={onSuggestionSelected}
        getSuggestionValue={getSuggestionValue}
        renderSuggestionsContainer={props.renderSuggestionsContainer}
        renderSuggestion={renderSuggestion || defaultRenderSuggestion(renderSuggestionContent)}
        renderInputComponent={renderInputComponent}
        inputProps={inputProps}
        shouldRenderSuggestions={shouldRenderSuggestions}
      />
    </div>
  );
}

AutoCompleteMulti.defaultProps = {
  isAlwaysRenderSuggestions: false,
  value: null,
  delayTime: 100,
  suggestions: [],
  onInputChange: () => {},
  onSuggestionsClearRequested: () => {},
  onSuggestionsFetchRequested: () => {},
  onSuggestionSelected: () => {},
  onChange: () => {},
  onClick: () => {},
  onBlur: () => {},
  onKeyDown: () => {},
  getSuggestionValue: defaultGetSuggestionValue,
};

export default AutoCompleteMulti;
