import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  ChangeEvent,
  FocusEvent,
  HTMLProps,
  MouseEvent,
  ReactNode,
} from 'react';

import { Correct, DeleteLight } from '@travel/icons/ui';
import { cx } from '@travel/utils';

import Alert from '../Alert';

import styles from './textField.module.scss';

export type Props = {
  /** Style name to control appearance of this component from parent component*/
  className?: string;

  /** Style name to control the appearance of label*/
  labelClassName?: string;

  /** Style name to control the appearance of Alert */
  alertClassName?: string;

  /** Style name to control the appearance of Alert title */
  alertTitleClassName?: string;

  /** Style name to control the appearance of Trailing Icon */
  trailingIconClassName?: string;

  /** html name of input element */
  name?: string;

  /** Label text above text field */
  label?: ReactNode;

  /** (controlled) Value of text field */
  value?: string | number;

  /** (uncontrolled) default value of text field */
  defaultValue?: string | number;

  /** The icon displayed in the beginning. */
  leadingIcon?: ReactNode;

  /** The icon displayed in the end. */
  trailingIcon?: ReactNode;

  /** Should this component have fluid width */
  isFullWidth?: boolean;

  /** Should text field be disabled */
  isDisabled?: boolean;

  /** If true, validated icon will be displayed at the end of text field */
  isValidated?: boolean;

  /** highlight input field till value changed. */
  shouldHighlightAtFirst?: boolean;

  /** Custom icon */
  customIcon?: ReactNode;

  /** if true, clear button will be displayed when on hover and focus
   * if false, clear button always displayed on the end of text field  */
  shouldHideClearButtonOnBlur?: boolean;

  /** If true, text field will have error style (red border) */
  hasError?: boolean;

  /** Error message to be displayed under text field. This requires hasError to be true. */
  errorMessage?: ReactNode;

  /** Unit to be displayed after text field */
  unit?: ReactNode;

  /** if true, 2 text fields are displayed in one line on PC, TL */
  isMultiple?: boolean;

  /** id ture,  middle size text feild is displayed */
  isMiddle?: boolean;

  /** html attribute to be passed down to input element */
  inputProps?: HTMLProps<HTMLInputElement>;

  /** Function to pass input reference to parent */
  inputRef?: (instance: HTMLInputElement | null) => void;

  /** Call back to be called after value gets changed. Event object will be passed through argument */
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;

  /** If true, text field will have succcess style (green border) */
  shouldShowSuccessMessage?: boolean;

  /** Number to define a character limit */
  characterLimit?: number;

  /** Success message to be displayed under text field. This requires showSuccessMessage to be true. */
  successMessage?: ReactNode;

  /** hidden label */
  isLabelHidden?: boolean;

  /**
   * we don't need to pass onClear just to clear value as already doing it with onChange
   * this can be useful to hanlde some different things or integrating with Third party lib
   * callback to be called after clear button click
   * */
  onClear?: (event: React.MouseEvent) => void;
} & Omit<HTMLProps<HTMLInputElement>, 'label' | 'onChange'>;

export default function TextField(props: Props) {
  const {
    className,
    labelClassName,
    alertClassName,
    alertTitleClassName,
    trailingIconClassName,
    name,
    label = '',
    value,
    defaultValue,
    leadingIcon = null,
    trailingIcon,
    isFullWidth = true,
    isDisabled,
    isValidated,
    shouldHighlightAtFirst,
    customIcon = null,
    shouldHideClearButtonOnBlur,
    hasError,
    isMultiple,
    isMiddle,
    errorMessage = '',
    unit = '',
    inputProps = {},
    onChange,
    onClick,
    onBlur,
    onKeyDown,
    onKeyPress,
    onFocus,
    inputRef,
    onClear,
    shouldShowSuccessMessage,
    successMessage = '',
    characterLimit,
    isLabelHidden,
    ...rest
  } = props;

  const isControlled = value !== undefined;

  const initialLoad = useRef(true);
  const shouldHighlight = initialLoad.current && shouldHighlightAtFirst;

  const { className: inputClassName, ...restInputProps } = inputProps;

  const [clearButtonStyle, setClearButtonStyle] = useState(
    onClear ? styles.visibleClearButton : '',
  );

  let input: HTMLInputElement;

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      event.persist();
      initialLoad.current = false;

      if (
        characterLimit !== undefined &&
        event.currentTarget.value?.trim().length > characterLimit
      ) {
        return false;
      }

      onChange?.(event);
      return true;
    },
    [characterLimit, onChange],
  );

  function handleClick(event: MouseEvent<HTMLInputElement>) {
    event.persist();
    onClick && onClick(event);
  }

  const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
    event.persist();
    onBlur && onBlur(event);
  };

  const handleClearClick = (event: MouseEvent) => {
    event.persist();
    onClear?.(event);
    input.focus();
  };

  const handleInputRef = (element: HTMLInputElement) => {
    input = element;
    inputRef && inputRef(element);
  };

  useEffect(() => {
    if (shouldHideClearButtonOnBlur) {
      setClearButtonStyle(styles.invisibleClearButton);
    } else if (inputProps?.type === 'time' || inputProps?.type === 'number') {
      setClearButtonStyle(styles.visibleClearButtonTime);
    }
  }, [inputProps, shouldHideClearButtonOnBlur]);

  return (
    <div
      className={cx(
        className,
        styles.container,
        isFullWidth ? styles.fullWidth : '',
        isMultiple ? styles.multiple : '',
        isMiddle ? styles.middle : '',
      )}
      {...rest}
    >
      {label && (
        <label
          className={cx(isLabelHidden ? styles.hidden : '', labelClassName)}
          htmlFor={inputProps.id || ''}
        >
          {label}
        </label>
      )}
      <div className={styles.textContainer}>
        <div className={cx(styles.inputContainer, unit ? styles.hasUnit : '')}>
          <input
            data-testid={'textField-input'}
            className={cx(
              inputClassName,
              shouldHighlight ? styles.highlight : '',
              styles.text,
              isDisabled ? styles.disabled : '',
              hasError ? styles.hasError : '',
              shouldShowSuccessMessage ? styles.showSuccessMessage : '',
              isValidated ? styles.isValidated : '',
              clearButtonStyle,
              leadingIcon !== null ? styles.withLeadingIcon : '',
              trailingIcon ? styles.withTrailingIcon : '',
            )}
            name={name}
            defaultValue={isControlled ? undefined : defaultValue}
            value={isControlled ? value : undefined}
            disabled={isDisabled}
            onBlur={handleBlur}
            onChange={handleChange}
            onClick={handleClick}
            onKeyDown={onKeyDown}
            onKeyPress={onKeyPress}
            onFocus={onFocus}
            ref={handleInputRef}
            {...restInputProps}
          />
          {leadingIcon && <span className={styles.leadingIcon}>{leadingIcon}</span>}
          {trailingIcon && (
            <span className={cx(styles.trailingIcon, trailingIconClassName)}>{trailingIcon}</span>
          )}
          {isValidated && <Correct className={styles.validatedIcon} />}
          {!isDisabled && onClear && value !== '' && (
            <DeleteLight
              data-input-name={name}
              className={styles.clearButton}
              onClick={handleClearClick}
              data-testid="textField-clear-button"
            />
          )}
          {value === '' && customIcon}
        </div>
        {unit && <span className={styles.unit}>{unit}</span>}
      </div>
      {hasError && errorMessage && (
        <Alert
          type="error"
          title={errorMessage}
          isValidation={true}
          isClosable={false}
          className={alertClassName}
          titleClassName={alertTitleClassName}
        />
      )}
      {shouldShowSuccessMessage && successMessage && (
        <p className={styles.successMessage}>{successMessage}</p>
      )}
    </div>
  );
}
