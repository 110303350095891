import { ReactNode } from 'react';

export type SavedSearchQuery = {
  startDate?: string;
  endDate?: string;
  noOfUnits?: string;
  adults?: number;
  guests?: number;
  children?: number;
  childrenAges?: Array<number>;
};

export type SuggestionCategory =
  | 'PROVIDER'
  | 'TRAIN_STATION'
  | 'AIRPORT'
  | 'WORLD_REGION'
  | 'REGION'
  | 'COUNTRY'
  | 'SUBDIVISION'
  | 'CITY'
  | 'DISTRICT'
  | 'POINT_OF_INTEREST'
  | 'BUS_STOP'
  | 'SEAPORT'
  | 'ISLAND'
  | 'HOT_SPRING'
  | 'POPULAR_DESTINATION'
  | 'NONE'
  | 'UNDEFINED';

export type SuggestionsItem = {
  id: string;
  pathId: string;
  type: 'AREA' | 'SPOT';
  category: SuggestionCategory;
  name: string;
  query?: SavedSearchQuery;
  isSavedSearch?: boolean;
};

export enum SuggestionType {
  API_SUGGESTIONS = 'API_SUGGESTIONS',
  SAVED_SEARCHES = 'SAVED_SEARCHES',
  POPULAR_DESTINATION = 'POPULAR_DESTINATION',
}

export type SuggestionsSection = {
  title: ReactNode | null;
  type: SuggestionType | null;
  suggestions: Array<SuggestionsItem>;
};

export type SuggestionsErrors = {
  statusCode: number;
  message: string;
};
