import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';

import { pushLocation } from '../../store/__router/actions';

export function formDataToObject(formData: FormData) {
  let object: { [key: string]: FormDataEntryValue } = {};
  for (const [key, value] of Array.from(formData.entries())) {
    object[key] = value;
  }

  return object;
}

export type FormSubmit = {
  defaultSubmit: () => void;
  query: string;
  queryObj: { [key: string]: FormDataEntryValue };
};

type FormProps = {
  action?: string;
  onSubmit?: ({ defaultSubmit, query, queryObj }: FormSubmit) => void;
  children: React.ReactNode;
} & Omit<React.HTMLProps<HTMLFormElement>, 'onSubmit'>;

// A component which works like the native form, but instead of firing a http request onSubmit, this form uses
// our client side router's pushLocation method
function Form(
  props: FormProps & {
    innerRef: React.Ref<HTMLFormElement | null>;
  },
) {
  const { action, onSubmit, children, innerRef, ...rest } = props;
  const dispatch = useDispatch();
  const formEl = useRef<HTMLFormElement | null>(null);

  return (
    <form
      ref={ref => {
        // Didn't figure out a better solution. reference: https://github.com/mui-org/material-ui/blob/master/packages/material-ui/src/utils/setRef.d.ts
        if (innerRef) {
          // @ts-ignore
          innerRef.current = ref;
        }
        formEl.current = ref;
      }}
      onSubmit={event => {
        event.preventDefault();

        if (formEl.current) {
          const formData = new FormData(formEl.current);
          const queryString = new URLSearchParams(formData as any).toString();
          const queryObj = formDataToObject(formData);

          const defaultSubmit = () => {
            // defaultSubmit will be executed if action prop exists
            if (action) {
              dispatch(
                pushLocation({
                  pathname: action,
                  search: queryString,
                }),
              );
            }
          };

          if (onSubmit) {
            onSubmit({ defaultSubmit, query: queryString, queryObj });
          } else {
            defaultSubmit();
          }
        }
      }}
      {...rest}
    >
      {children}
    </form>
  );
}

const FormWithRef = React.forwardRef<HTMLFormElement, FormProps>((props, ref) => (
  <Form {...props} innerRef={ref}></Form>
));

FormWithRef.displayName = 'Form';

export default FormWithRef;
