import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { pushLocation } from 'store/__router/actions';

import paths from 'core/universalRouter/paths';
import { SuggestionsItem } from 'Suggestions-Types';

// NOTE: This hook is expected to use on every SearchForm that contains PlaceInput
// To handle the redirection of Place / Provider / Area (in the future)
function usePlaceRedirect(isAllowAreaRedirection = false) {
  const dispatch = useDispatch();
  const redirect = useCallback(
    (place: SuggestionsItem, queryString?: string) => {
      let pathname;

      if (place.category === 'PROVIDER') {
        pathname = paths.providerInfo.pathResolver(place.pathId);
      } else if (
        isAllowAreaRedirection &&
        place.type === 'AREA' &&
        place.category === 'SUBDIVISION'
      ) {
        pathname = paths.area.pathResolver(place.pathId);
      } else {
        pathname = paths.providerList.pathResolver(place.pathId);
      }

      dispatch(
        pushLocation({
          pathname,
          search: queryString,
        }),
      );
    },
    [dispatch, isAllowAreaRedirection],
  );

  return redirect;
}

export default usePlaceRedirect;
