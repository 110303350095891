import React from 'react';

import { Hotel } from '@travel/icons/service';
import useDeviceType from '@travel/traveler-core/hooks/useDeviceType';

import { Translate } from 'core/translate';

import styles from './placeInput.module.scss';

function EmptyState() {
  const isMobile = useDeviceType() === 'sp';

  return (
    <div className={styles.emptyStateWrapper}>
      <Hotel className={styles.hotelIcon} size={isMobile ? 80 : 60} color="pastelGray" />
      <p className={styles.title}>
        <Translate id="Traveler_Input_Error.Global_Search.Destination_Search.No_Result_Message" />
      </p>
      <span className={styles.description}>
        <Translate id="Traveler_Input_Error.Global_Search.Destination_Search.No_Result_Suggestion" />
      </span>
    </div>
  );
}

export default EmptyState;
