import { LSgetItem, LSremoveItem, LSsetItem, toAppSearchObject } from '@travel/traveler-core/utils';
import { isNotEmptyArray } from '@travel/utils';

import { SavedSearchQuery, SuggestionsItem } from 'Suggestions-Types';

export const savedSearchKey = 'localSearchSave';
const maxSavedSearchCount = 5;

type SearchInLocal = {
  lang: string;
  savedSearch: Array<{
    place: SuggestionsItem;
    query: SavedSearchQuery;
  }>;
};

export function savedSearchStore(
  lang: string,
  place: SuggestionsItem,
  queryObj: Record<string, FormDataEntryValue>,
) {
  const searchInLocal = LSgetItem<SearchInLocal>(savedSearchKey) || null;
  const isSameLang = searchInLocal?.lang === lang;

  if (!isSameLang) {
    LSremoveItem(savedSearchKey);
  }

  const queryObjFromData = toAppSearchObject(queryObj as Record<string, string>);

  const adults = queryObjFromData.adults ? Number(queryObjFromData.adults as string) : null;
  const childrenAges = queryObjFromData.childrenAges
    ? (queryObjFromData.childrenAges as string).split(',').map(el => Number(el))
    : null;
  const guests = (adults || 0) + (childrenAges?.length || 0);

  const query: SavedSearchQuery = {
    ...(adults ? { adults: adults } : {}),
    ...(queryObjFromData.childrenAges
      ? { childrenAges: (queryObjFromData.childrenAges as string).split(',').map(el => Number(el)) }
      : {}),
    ...(queryObjFromData.endDate ? { endDate: queryObjFromData.endDate as string } : {}),
    ...(guests ? { guests: guests } : {}),
    ...(queryObjFromData.noOfUnits ? { noOfUnits: queryObjFromData.noOfUnits as string } : {}),
    ...(queryObjFromData.startDate ? { startDate: queryObjFromData.startDate as string } : {}),
  };

  const queryStr = JSON.stringify(query);

  // Check if the search if already is stored
  if (isSameLang && searchInLocal?.savedSearch && isNotEmptyArray(searchInLocal?.savedSearch)) {
    for (let i = 0; i < searchInLocal?.savedSearch.length; i++) {
      const savedSearch = searchInLocal?.savedSearch[i];
      if (
        JSON.stringify(savedSearch.query) === queryStr &&
        savedSearch.place.pathId === place.pathId
      ) {
        searchInLocal?.savedSearch.splice(i, 1);
        break;
      }
    }
  }

  const filteredSearch = searchInLocal && searchInLocal.savedSearch;

  const savedSearch =
    filteredSearch && isSameLang && searchInLocal?.savedSearch.length !== 0
      ? [{ place, query }, ...filteredSearch]
      : [{ place, query }];

  const newSearchInLocal: SearchInLocal = {
    lang,
    savedSearch: savedSearch.slice(0, maxSavedSearchCount),
  };

  LSsetItem(savedSearchKey, newSearchInLocal);
}

export const savedSearchGet = () => LSgetItem<SearchInLocal>(savedSearchKey) || null;

export const savedSearchToPlaceSuggestion = (lang: string): Array<SuggestionsItem> | null => {
  const searchInLocal = LSgetItem<SearchInLocal>(savedSearchKey) || null;

  const isSameLang = searchInLocal?.lang === lang;

  if (!searchInLocal || !isSameLang) {
    return null;
  }

  if (searchInLocal?.savedSearch && isNotEmptyArray(searchInLocal.savedSearch)) {
    return searchInLocal.savedSearch.map(({ place, query }) => ({
      ...place,
      isSavedSearch: true,
      query,
    }));
  } else {
    return null;
  }
};

export const deleteSavedSearch = (suggestionString: string) => {
  const savedLocalSearch = LSgetItem<SearchInLocal>(savedSearchKey) || null;

  if (!savedLocalSearch) {
    return;
  }

  if (savedLocalSearch?.savedSearch && isNotEmptyArray(savedLocalSearch.savedSearch)) {
    const index = savedLocalSearch.savedSearch.findIndex(savedSearch => {
      const savedLocalSearchItem = JSON.stringify({
        ...savedSearch.query,
        name: savedSearch.place.name,
      });
      return savedLocalSearchItem === suggestionString;
    });

    if (index !== -1) {
      savedLocalSearch.savedSearch.splice(index, 1);
    }
  }

  LSsetItem(savedSearchKey, savedLocalSearch);
};
