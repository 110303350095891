import React, { useEffect } from 'react';

function useOutsideClick(
  dropDownRef: React.RefObject<HTMLDivElement>,
  inputRef: React.RefObject<HTMLInputElement>,
  fn: () => void,
) {
  useEffect(() => {
    function handleClickOutside(event: any) {
      if (
        dropDownRef?.current &&
        !dropDownRef?.current.contains(event.target) &&
        inputRef.current &&
        !inputRef.current.contains(event.target)
      ) {
        fn();
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropDownRef, fn, inputRef]);
}

export default useOutsideClick;
