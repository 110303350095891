import loadable from '@loadable/component';
import React, { ReactNode } from 'react';

const Dialog = loadable(() => import('./Dialog'), { ssr: false });

export type Props = {
  /** Custom style for wrapper */
  className?: string;
  /** Custom style for content div */
  contentClassName?: string;
  /** Custom style for header div */
  headerClassName?: string;
  /** Display contents of the dialog */
  children?: ReactNode;
  /** Boolean to set visibility of the dialog */
  isOpen?: boolean;
  /** Boolean to see if the device is a iOS device */
  isIOS: boolean;
  /** There is some rare case which is, the element has been displayed inside another dialog
   * and placed behind the parent's dialog. If it's true, the z-index will be increased by 1 from default
   * The value should be **false** if you have not faced any issue
   */
  isDisplayedOnDialog?: boolean;
  /** Title of the modal */
  title?: ReactNode;
  /** function to call on dialog close */
  onClose?: (event: React.MouseEvent | React.KeyboardEvent) => void;
  /** Flag to enable animation when component mounted */
  hasAnimation?: boolean;
};

const HtmlDialog = (props: Props) => {
  return <Dialog {...props} />;
};

export default HtmlDialog;
